<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">商品分类</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 800px;">
				<div>
					<a-button type="primary" icon="plus" @click="addGoodsCategoryAct()">添加分类</a-button>
				</div>
				
				<div class="mt30">
					<div class="wxb-table-white">
						<a-table rowKey="category_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							
							<div slot="icon" slot-scope="icon,record" class="goods-type-icon">
								<img v-if="icon != null" :src="icon"/>
							</div>
							
							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editGoodsCategoryAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											  </a-menu-item>
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="del(record)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除</span>
												</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	
		<div v-if="editGoodsCategoryVisible">
			<edit-goods-category :category_id="category_id" :visible="editGoodsCategoryVisible" @cancel="cancelEditGoodsCategory" @ok="okEditGoodsCategory"></edit-goods-category>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editGoodsCategory from './components/index/modal/editGoodsCategory.vue';
	export default {
		components:{
			editGoodsCategory,
		},
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				editGoodsCategoryVisible:false,
				category_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					
					{title: '分类名称',dataIndex: 'name',align: 'center'},
					{title: '分类ID',dataIndex: 'category_id',align: 'center'},
					{title: '分类图标',dataIndex: 'icon',align: 'center',scopedSlots: {customRender: 'icon'}},
					{title: '商品数',dataIndex: 'goods_count',align: 'center',ellipsis: true},
					{title: '创建时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getGoodsCategoryList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			addGoodsCategoryAct(){
				this.category_id = 0;
				this.editGoodsCategoryVisible=true;
			},
			editGoodsCategoryAct(record){
				this.category_id = record.category_id;
				this.editGoodsCategoryVisible=true;
			},
			cancelEditGoodsCategory(){
				this.editGoodsCategoryVisible=false;
			},
			okEditGoodsCategory(){
				this.editGoodsCategoryVisible=false;
				this.getLists();
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个分类吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delGoodsCategory',{
								category_id:record.category_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.goods-type-icon img{
		width: 60px;
		height: 60px;
	}
</style>